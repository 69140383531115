import { forwardRef } from "react";
import { Control } from "react-hook-form";
import { FormField } from "~/components/v2/Form";
import { TagsInput } from "~/components/v2/TagsInput";
import { cn } from "~/utils/cn";

export interface TagFieldProps {
  label: string;
  error?: string;
  control: Control<any>;
  name: string;
  helper?: string;
}

export const TagField = forwardRef<HTMLInputElement, TagFieldProps>(
  (
    { label, error, control, name, helper = "Separate tags with a comma." },
    ref,
  ) => (
    <FormField
      control={control}
      name={name}
      render={({ field: { onChange, value = [], ref } }) => {
        return (
          <div className="flex flex-col gap-1">
            {label && <span className="text-sm font-semibold">{label}</span>}
            <TagsInput
              ref={ref}
              values={value ?? []}
              onChange={onChange}
              className={cn(
                "min-h-[2.625rem] py-[.375rem]",
                `${value?.length ? "border-[#595959]" : "border-[#ccc]"}`,
                error
                  ? "border-error bg-[#FFF5F5] text-error placeholder-shown:border-error"
                  : "",
              )}
              inputClassName={`${
                !!error &&
                "border-error text-error bg-[#FFF5F5] placeholder-shown:border-error"
              }`}
            />
            <span className={`text-sm text-${error ? "error" : "[#737373]"}`}>
              {error ? (
                <>
                  <i className="mi-circle-error"></i> {error}
                </>
              ) : (
                helper
              )}
            </span>
          </div>
        );
      }}
    />
  ),
);

TagField.displayName = "TagField";

import React, { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ControlDisabledProps } from "./control-disabled.types";

export const ControlDisabled: React.FC<ControlDisabledProps> = ({
  disabled,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(!disabled);
  }, [disabled]);

  return null;
};

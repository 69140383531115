import { tv } from "tailwind-variants";

import { InputEditorVatriants } from "./editor.types";

export const inputEditorClassName = tv({
  slots: {
    containerStyles:
      "w-full overflow-hidden rounded-xl border border-bg-neutral-10 bg-white focus-within:border-brand-primary-rest",
    headerStyles: "border-b border-bg-neutral-10 px-3 py-2",
    placeholderStyles:
      "pointer-events-none absolute left-2.5 top-2 inline-block select-none font-normal text-neutral-tertiary",
    richTextStyles:
      "h-[100px] overflow-y-auto px-3 py-2 text-sm/6 outline-none",
  },
  variants: {
    [InputEditorVatriants.error]: {
      true: {
        containerStyles: "border-status-critical",
      },
    },
    [InputEditorVatriants.disabled]: {
      true: {
        containerStyles: "opacity-50",
      },
    },
    [InputEditorVatriants.hideToolbar]: {
      true: {
        headerStyles: "hidden",
      },
    },
  },
});

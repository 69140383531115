import { $createParagraphNode, $getRoot } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";

import { useEffect, useRef } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LoadInitialContentProps } from "./load-initial-content.types";

export const LoadInitialContent: React.FC<LoadInitialContentProps> = ({
  initialContent,
}) => {
  const [editor] = useLexicalComposerContext();
  const isInitialLoad = useRef(true);

  useEffect(() => {
    if (!initialContent || !isInitialLoad.current) return;

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialContent, "text/html");

      const nodes = $generateNodesFromDOM(editor, dom);

      $getRoot().clear();
      nodes.forEach(n => {
        if (n.__type !== "paragraph") {
          const paragraph = $createParagraphNode();
          paragraph.append(n);
          return $getRoot().append(paragraph);
        }
        return $getRoot().append(n);
      });
    });

    // Mark as loaded to prevent future updates
    isInitialLoad.current = false;
  }, [initialContent, editor]);

  return null;
};


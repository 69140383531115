import { tv } from "tailwind-variants";
import { InputHTMLAttributes, ReactNode, forwardRef } from "react";
import { Input } from "~/components/v2/Input";
import { cn } from "~/utils/cn";

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  error?: string;
  startAdornment?: ReactNode;
  helper?: string;
  placeholder?: string;
}

const helperVariants = tv({
  base: "text-xs flex items-center gap-1",
  variants: { state: { error: "text-error", default: "text-[#737373]" } },
  defaultVariants: {
    state: "default",
  },
});

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      error,
      startAdornment,
      required,
      helper,
      className,
      placeholder,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="flex flex-col gap-1">
        {label && (
          <span className="text-sm font-semibold">
            {label}
            {required && "*"}
          </span>
        )}
        <div className="relative">
          <Input
            placeholder={placeholder || "Example"}
            className={cn(
              `peer h-[2.625rem] border-[#595959] py-2 placeholder:text-[#999999] placeholder-shown:border-[#ccc] ${
                error &&
                "border-error bg-[#FFF5F5] text-error placeholder-shown:border-error"
              } ${startAdornment && "pl-7"}`,
              className,
            )}
            {...props}
            ref={ref}
            aria-label={placeholder}
          />
          <span
            className={`absolute left-0 top-0 flex h-full items-center px-3 peer-placeholder-shown:text-[#999] ${
              error && "text-error"
            }`}
          >
            {startAdornment}
          </span>
        </div>
        {(error || helper) && (
          <span
            className={helperVariants({ state: error ? "error" : "default" })}
          >
            {error && <i className="mi-circle-error"></i>}
            {error || helper}
          </span>
        )}
      </div>
    );
  },
);

TextField.displayName = "TextField";

"use client";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import { createPortal } from "react-dom";
import { $isHeadingNode } from "@lexical/rich-text";
import OrderedList from "~/icons/OrderedList";
import UnorderedList from "~/icons/UnorderedList";
import Bold from "~/icons/Bold";
import Italic from "~/icons/Italic";
import Underline from "~/icons/Underline";
import Strikethrough from "~/icons/Strikethrough";
import Link from "~/icons/Link";
import { getSelectedNode, isInsideInterpolation } from "./toolbar.utils";
import { FloatingLinkEditor } from "./components/floating-link-editor";
import { LOW_PRIORITY } from "./toolbar.constants";
import { ToolbarButton } from "./components/toolbar-button";
import { createCommand } from "lexical";
import { InterpolationButton } from "./components/interpolation-button";
import { interpolationOptions } from "./toolbar.mocks";

export const INSERT_STRING_INTERPOLATION_COMMAND = createCommand<string>();

interface Props {
  disabled?: boolean;
  interpolationFeatureFlag?: boolean;
}

export function ToolbarPlugin({ disabled, interpolationFeatureFlag }: Props) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isInterpolation, setIsInterpolation] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));

      interpolationFeatureFlag &&
        setIsInterpolation(
          isInsideInterpolation(anchorNode.getTextContent(), selection),
        );

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LOW_PRIORITY,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        payload => {
          setCanUndo(payload);
          return false;
        },
        LOW_PRIORITY,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        payload => {
          setCanRedo(payload);
          return false;
        },
        LOW_PRIORITY,
      ),
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    if (interpolationFeatureFlag) {
      return editor.registerCommand(
        INSERT_STRING_INTERPOLATION_COMMAND,
        payload => {
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              selection.insertText(`{{${payload}}}`);
            }
          });
          return true;
        },
        LOW_PRIORITY,
      );
    }
  }, [editor]);

  return (
    <div className="flex gap-2" ref={toolbarRef}>
      <div className="flex gap-x-2">
        <ToolbarButton
          disabled={disabled}
          data-testid="bold-button"
          isActive={isBold}
          label={<Bold />}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
        />
        <ToolbarButton
          disabled={disabled}
          data-testid="italic-button"
          isActive={isItalic}
          label={<Italic />}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
        />
        <ToolbarButton
          disabled={disabled}
          data-testid="underline-button"
          isActive={isUnderline}
          label={<Underline />}
          onClick={() =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
          }
        />

        {interpolationFeatureFlag && (
          <InterpolationButton
            active={isInterpolation}
            disabled={disabled}
            options={interpolationOptions}
            onSelect={value =>
              editor.dispatchCommand(
                INSERT_STRING_INTERPOLATION_COMMAND,
                `${value}`,
              )
            }
          />
        )}

        <ToolbarButton
          disabled={disabled}
          data-testid="strikethrough-button"
          isActive={isStrikethrough}
          label={<Strikethrough />}
          onClick={() =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
          }
        />
      </div>
      <div className="flex gap-0.5">
        <ToolbarButton
          disabled={disabled}
          data-testid="ul-button"
          isActive={blockType === "ul"}
          label={<UnorderedList />}
          onClick={formatBulletList}
        />
        <ToolbarButton
          disabled={disabled}
          data-testid="ol-button"
          isActive={blockType === "ol"}
          label={<OrderedList />}
          onClick={formatNumberedList}
        />
      </div>
      <div className="flex gap-0.5">
        <ToolbarButton
          disabled={disabled}
          data-testid="link-button"
          isActive={isLink}
          label={<Link />}
          onClick={insertLink}
        />
        {isLink &&
          createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
      </div>
    </div>
  );
}

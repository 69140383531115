export enum AdsKind {
  small = "small",
  medium = "medium",
  large = "large",
}

export const AdsSize = {
  [AdsKind.small]: {
    width: 320,
    height: 50,
  },
  [AdsKind.medium]: {
    width: 300,
    height: 250,
  },
  [AdsKind.large]: {
    width: 728,
    height: 90,
  },
} as const;

export interface AdvertisementProps {
  size?: keyof typeof AdsSize;
  className?: string;
}

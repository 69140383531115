import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "~/scalis-components/core";
import { Body, BodySize } from "~/scalis-components/core/typography";
import { cn } from "~/utils/cn";

interface InterpolationChildOption {
  id: number;
  label: string;
}

interface InterpolationParentOption {
  id: number;
  label: string;
  icon: string;
  options: InterpolationChildOption[];
}

interface Props {
  active?: boolean;
  disabled?: boolean;
  options: InterpolationParentOption[];
  onSelect: (value: number) => void;
}

export const InterpolationButton = ({
  active,
  disabled,
  options,
  onSelect,
}: Props) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          "flex h-6 w-6 items-center justify-center rounded text-typography-high-contrast transition-colors",
          {
            "hover:bg-bluegray-40": !disabled,
            "text-typography-low-contrast hover:bg-transparent": disabled,
            "bg-transparent": !active,
            "bg-white-70 text-white hover:bg-white-70": active && !disabled,
          },
        )}
      >
        <i className="fa-regular fa-brackets-curly" />
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="w-64 rounded-xl bg-white p-2"
        align="start"
        collisionPadding={10}
      >
        {options.map(parentOption => (
          <DropdownMenuSub key={parentOption.id}>
            <DropdownMenuSubTrigger
              hideTrigger={true}
              className="flex h-10 w-full cursor-pointer justify-between rounded hover:bg-neutral-20"
            >
              <div className="flex items-center gap-2">
                <i className={parentOption.icon} />
                <Body size={BodySize.small}>{parentOption.label}</Body>
              </div>

              <div className="flex items-center gap-2">
                {parentOption.options.length}
                <i className="fa fa-caret-right" />
              </div>
            </DropdownMenuSubTrigger>

            <DropdownMenuSubContent
              sideOffset={15}
              alignOffset={-38}
              className="w-56 rounded-xl bg-white p-2"
            >
              {parentOption.options.map(childOption => (
                <div
                  key={childOption.id}
                  className="flex h-10 cursor-pointer items-center gap-2 rounded p-2 hover:bg-neutral-20"
                  onClick={() => onSelect(childOption.id)}
                >
                  <i className="fa-regular fa-clipboard" />
                  <Body size={BodySize.small}>{childOption.label}</Body>
                </div>
              ))}
            </DropdownMenuSubContent>
          </DropdownMenuSub>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

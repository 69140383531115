import { RangeSelection } from "lexical";
import { $isAtNodeEnd } from "@lexical/selection";

export function positionEditorElement(
  editor: HTMLDivElement,
  rect: DOMRect | null,
) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

export function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

export function isInsideInterpolation(
  textContent: string,
  selection: RangeSelection,
) {
  const cursorOffset = selection.anchor.offset;
  const beforeCursor = textContent.slice(0, cursorOffset);
  const afterCursor = textContent.slice(cursorOffset);
  const isInsideInterpolation =
    beforeCursor.endsWith("{{") && afterCursor.startsWith("}}");

  return isInsideInterpolation;
}

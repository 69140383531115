import { $getRoot } from "lexical";

import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ClearContentProps } from "./clear-content.types";

export const ClearContent: React.FC<ClearContentProps> = ({ value }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const editorEmptyState = '<p class="m-0 mb-2 relative"><br></p>';
    if (!value || value === editorEmptyState) {
      editor.update(() => {
        $getRoot().clear();
      });
    }
  }, [value]);

  return null;
};

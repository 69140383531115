import { cn } from "~/utils/cn";
import { ToolbarButtonProps } from "./toolbar-button.types";

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  isActive,
  label,
  onClick,
  ["data-testid"]: testId,
  disabled,
}) => {
  return (
    <button
      disabled={disabled}
      data-testid={testId}
      className={cn(
        "flex h-6 w-6 items-center justify-center rounded text-typography-high-contrast transition-colors",
        {
          "hover:bg-bluegray-40": !disabled,
          "text-typography-low-contrast hover:bg-transparent": disabled,
          "bg-transparent": !isActive,
          "bg-white-70 text-white hover:bg-white-70": isActive && !disabled,
        },
      )}
      onClick={onClick}
      type="button"
    >
      {label}
    </button>
  );
};

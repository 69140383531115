const parentOption1 = {
  id: 1,
  label: "Parent Option 1",
  icon: "fa-regular fa-pen",
  options: [
    { id: 1, label: "Child Option 1" },
    { id: 2, label: "Child Option 2" },
    { id: 3, label: "Child Option 3" },
    { id: 4, label: "Child Option 4" },
  ],
};
const parentOption2 = {
  id: 2,
  label: "Parent Option 2",
  icon: "fa-regular fa-trash",
  options: [
    { id: 5, label: "Child Option 5" },
    { id: 6, label: "Child Option 6" },
    { id: 7, label: "Child Option 7" },
    { id: 8, label: "Child Option 8" },
  ],
};
const parentOption3 = {
  id: 3,
  label: "Parent Option 3",
  icon: "fa-regular fa-pen",
  options: [
    { id: 9, label: "Child Option 9" },
    { id: 10, label: "Child Option 10" },
    { id: 11, label: "Child Option 11" },
    { id: 12, label: "Child Option 12" },
  ],
};

export const interpolationOptions = [
  parentOption1,
  parentOption2,
  parentOption3,
];

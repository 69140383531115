import { ChangeHandler } from "react-hook-form";

export enum InputEditorVatriants {
  disabled = "disabled",
  error = "error",
  hideToolbar = "hideToolbar",
}

export interface InputEditorProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  setValue?: (name: string, value: string) => void;
}

export interface EditorProps {
  label?: string;
  initialContent?: string;
  setValue?: (name: string, value: string) => void;
  onChange?: ChangeHandler;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  contentClassName?: string;
  placeholderClassName?: string;
  hidePlaceHolderOnFocus?: boolean;
  hideToolbar?: boolean;
  toolbarDivider?: boolean;
  value?: string;
  error?: string;
  name?: string;
  innerContainerClassName?: string;
  interpolationFeatureFlag?: boolean;
  onSubmit?: (value: string) => void;
  onSubmitLoading?: boolean;
}
